import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import axios from "axios"

import { Typography, Container, makeStyles } from "@material-ui/core"

import SEO from "../components/seo"

// const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginTop: "3.5rem",
    marginBottom: "3.5rem",
  },
}))

const AboutUs = ({ pageContext }) => {
  const {i18n} = useTranslation()
  const classes = useStyles()
  const { pLang } = pageContext

  const [aboutUsData, setAboutUsData] = useState()

  useEffect(() => {
    i18n.changeLanguage(pLang)
  }, [i18n, pLang])

  // const breadcrumbs = [
  //   {
  //     text: t("header.aboutUsPage"),
  //   },
  // ]

  function extractContent(s) {
    var span = documentGlobal.createElement("span")
    span.innerHTML = s
    return span.textContent || span.innerText
  }

  useEffect(() => {
    axios
      .get(`${process.env.GATSBY_API_URL}/options/about_us`, {
        headers: { "Accept-Language": pLang },
      })
      .then(res => {
        setAboutUsData(extractContent(res.data.about_us))
      })
  }, [pLang])

  // const Basic = () => (
  //   <BodyClassName className="helloworld">
  //     <h1>You ate a whole wheel of cheese?</h1>
  //   </BodyClassName>
  // )

  return (
    <>
      <SEO title="About Us" lang={pLang} />
      <Container className={classes.mainContainer}>
        <Typography variant="h6" component="h2">
          {aboutUsData}
        </Typography>
        {/* {Basic} */}
        {/* <BodyClassName className="helloworld">
          <h1>You ate a whole wheel of cheese?</h1>
        </BodyClassName> */}
      </Container>
    </>
  )
}
export default AboutUs
